import React from 'react';
import styles from "./ProjectsMW.module.css";
import {connect} from "react-redux";
import ListOfProjects from "../ListOfProjects/ListOfProjects";
import ViewProject from "../ViewProject/ViewProject";
import ListOfCategories from "../ListOfCategories/ListOfCategories";

class ProjectsMW extends React.Component {
    mw = React.createRef();

    render() {
        let view;
        if (this.props.projects.modal_window) {
            if (this.props.projects.display_mode === 0)
                view = <ListOfCategories />;
            else if (this.props.projects.display_mode === 1)
                view = <ListOfProjects />;
            else if (this.props.projects.display_mode === 2)
                view = <ViewProject />;
        }

        return (
            <div className={styles.BoxShadow} ref={this.mw}>
                <div className={styles.Container}>
                    <header>
                        <h3>
                            {this.props.projects.title}
                        </h3>
                        <span className={styles.Exit} onClick={this.props.hideProjectsWindow.bind(this)}>×</span>
                    </header>
                    {
                        view
                    }
                </div>
            </div>
        );
    }

    componentDidMount() {
        if (!this.props.projects.modal_window)
            this.mw.current.classList.add("hide");
        else
            this.mw.current.classList.remove("hide");
    }

    componentDidUpdate() {
        if (!this.props.projects.modal_window)
            this.mw.current.classList.add("hide");
        else
            this.mw.current.classList.remove("hide");
    }
}

function mapStateToProps (state) {
    return {
        projects: state.projects,
        auth: state.auth
    }
}

function mapDispatchToProps (dispatch) {
    return {
        hideProjectsWindow: () => dispatch({type: 'hideProjectsWindow'}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsMW);
