import React, {Component} from 'react';
import './App.css'
import {Redirect, Route, Switch as RouterSwitch} from 'react-router';
import Hotkeys from 'react-hot-keys';
import {connect} from 'react-redux';

import licences from "./components/licences/licences";
import achievements from "./components/achievements/achievements";
import main from "./components/main/main";
import projects from "./components/projects/projects";
import services from "./components/services/services";

import Preloader from "./common/Preloader/Preloader"
import Header from "./common/Header/Header";
import Footer from "./common/Footer/Footer";
import ModalWindow from "./common/ModalWindow/ModalWindow";

class App extends Component {
    preloader = React.createRef();
    timer;

    constructor(props) {
        super(props);
    }

    onAdmin() {
        this.props.showAdminLogin();
    }

    render () {
        const logInp = React.createRef();
        const logPass = React.createRef();
        return (
            <React.Fragment>
                <Hotkeys keyName="ctrl+q" onKeyDown={this.onAdmin.bind(this)}>
                    <ModalWindow>
                        <div className="adminForm">
                            <input ref={logInp} type="text" placeholder="name"/>
                            <input ref={logPass} type="password" placeholder="password"/>
                            <button onClick={() => {
                                const body = {
                                    login: logInp.current.value,
                                    password: logPass.current.value
                                };
                                fetch('api/login', {
                                    method: 'POST',
                                    body: JSON.stringify(body)
                                })
                                    .then(resp => resp.json())
                                    .then(authState => {
                                        this.props.switchToAdmin(authState.auth);
                                    });
                            }}>Log in</button>
                        </div>
                    </ModalWindow>
                    <Preloader ref={this.preloader}/>
                    <Header />
                    <RouterSwitch>
                        <Route path="/main" component={main}/>
                        <Route path="/projects" component={projects}/>
                        <Route path="/services" component={services}/>
                        <Route path="/licences" component={licences} />
                        <Route path="/achievements" component={achievements} />

                        <Redirect to="/main" />
                    </RouterSwitch>
                    <img src="/content/Icon/up.png" className="GoToUp" onClick={this.GoToUp.bind(this)}/>
                    <Footer />
                </Hotkeys>
            </React.Fragment>
        );
    }

    GoToUp = () => {
        let y = window.pageYOffset;
        const interval = setInterval(() => {
            y -= 18;
            window.scrollTo(0, y);
            if (y <= 0)
                clearInterval(interval);
        }, 8);
    };

    componentWillMount() {
        document.title = 'БК БУДСЕРВІСГРУП';
    };

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.preloader.current.classList.value += " hide";
        }, 1500);

        fetch('api/isauth')
            .then(resp => resp.json())
            .then(authState => {
                this.props.switchToAdmin(authState.isAuth);
            });
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        modal_window: state.modal_window
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showAdminLogin: () => dispatch({type: 'showAdminLogin'}),
        switchToAdmin: (flag) => dispatch({type: 'switchToAdmin', payload: flag})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
