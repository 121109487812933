import React from "react";
import styles from './ModalWindow.module.css';
import {connect} from 'react-redux';

class ModalWindow extends React.Component {
    mw = React.createRef();

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={styles.BoxShadow} ref={this.mw}>
                <div className={styles.Container}>
                    <header>
                        <span className={styles.Exit}  onClick={this.props.hideAdminLogin.bind(this)}>×</span>
                    </header>
                    <div>
                        {
                            !this.props.auth ?
                                this.props.children
                                :
                                <React.Fragment>
                                    <h2 className={styles.Admin}>Бажаєте вийти?</h2>
                                    <button onClick={() => {
                                        fetch('api/auth/exit')
                                            .then(resp => resp.json())
                                            .then(authState => {
                                                this.props.switchToAdmin(false);
                                            });
                                    }} className={styles.Yes}>Так</button>
                                </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        if (this.props.modal_window)
            this.mw.current.classList.remove("hide");
        else
            this.mw.current.classList.add("hide");
    }

    componentDidUpdate() {
        if (this.props.modal_window)
            this.mw.current.classList.remove("hide");
        else
            this.mw.current.classList.add("hide");
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        modal_window: state.modal_window
    }
}

function mapDispatchToProps(dispatch) {
    return {
        hideAdminLogin: () => dispatch({type: 'hideAdminLogin'}),
        switchToAdmin: (flag) => dispatch({type: 'switchToAdmin', payload: flag})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalWindow);
