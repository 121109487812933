import React from 'react';
import styles from './ListOfCategories.module.css';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class ListOfCategories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: []
        }
    }

    render() {
        return (
            <main className={styles.Main}>
                {
                    this.state.items.map((item, index) => {
                        return(
                            <div className={styles.Item} key={index}>
                                {
                                    item.is_link === "1" ?
                                            this.props.auth
                                                ? <h3 onClick={this.ManageCategory.bind(this, item.name, item.id, item.is_link, item.url)}>{item.name}</h3>
                                                : <a href={item.url}><h3>{item.name}</h3></a>
                                        :
                                            this.props.auth
                                            ? <h3 onClick={this.ManageCategory.bind(this, item.name, item.id, item.is_link, item.url)}>{item.name}</h3>
                                            : <h3 onClick={this.props.goToCategoryId.bind(this, item.name, item.id)}>{item.name}</h3>
                                }
                                <img alt="" src={item.photo}/>
                            </div>
                        );
                    })
                }
                {
                    this.props.auth ?
                        <div className={styles.Item + " " + styles.Plus} onClick={this.AddNew.bind(this)}>
                            <img alt="" src="/content/Icon/plus.png"/>
                        </div>
                        : null
                }
            </main>
        );
    }

    loadData = () => {
        const category = this.props.projects.mode;
        fetch('api/categories/getAll', {
            method: 'POST',
            body: JSON.stringify({
                'type': category
            })
        })
            .then(resp => resp.json())
            .then(categories => {
                this.setState({
                    items: categories
                })
            });
    };

    async componentDidMount() {
        this.loadData();
    }

    ManageCategory = (name, id, is_link, url) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                const nameRef = React.createRef();
                const photoRef = React.createRef();

                return (
                    <div className={styles.AddNewItem}>
                        <h1>Manage category</h1>
                        <span>
                            <input ref={nameRef} placeholder="Name of category"/>
                            <button onClick={() => {
                                const data = {
                                    id: id,
                                    name: nameRef.current.value
                                };
                                fetch("api/category/setName", {
                                    method: 'POST',
                                    body: JSON.stringify(data),
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                }).then(resp => resp.json()).then(data => {
                                    if (!data.status) {
                                        alert("Something error");
                                    } else {
                                        this.loadData();
                                    }
                                });

                                onClose();
                            }}>Change</button>
                        </span>
                        <label>
                            <input onChange={() => {
                                const data = new FormData();
                                data.append('picture', photoRef.current.files[0]);
                                data.append('id', id);

                                fetch("api/category/setPreview", {
                                    method: 'POST',
                                    body: data
                                }).then(resp => resp.json()).then(data => {
                                    if (!data.status) {
                                        alert("Something error");
                                    } else {
                                        this.loadData();
                                    }
                                });

                                onClose();
                            }} className="none" ref={photoRef} type="file"/>
                            <button>Load photo</button>
                        </label>

                        <div className={styles.Panel}>
                            <button onClick={
                                () => {
                                    if (is_link === "0") {
                                        this.props.goToCategoryId.call(this, name, id);
                                        onClose();
                                    }
                                }
                            }>{is_link === "1" ? <a href={url}>Follow</a> : "Open"}</button>
                            <button
                                onClick={() => {
                                    const data = {
                                        id: id
                                    };
                                    fetch("api/category/delete", {
                                        method: 'POST',
                                        body: JSON.stringify(data),
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    }).then(resp => resp.json()).then(data => {
                                        if (!data.status) {
                                            alert("Something error");
                                        } else {
                                            this.loadData();
                                        }
                                    });

                                    onClose();
                                }}
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                );
            }
        });
    };

    AddNew = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                const nameRef = React.createRef();
                const urlRef = React.createRef();
                const isLinkRef = React.createRef();

                return (
                    <div className={styles.AddNewItem}>
                        <h1>Creating a new item</h1>
                        <input ref={nameRef} placeholder="Name of category"/>
                        <input ref={urlRef} placeholder="Url (optional)"/>
                        <label><input ref={isLinkRef} type="checkbox"/>It's category with url</label>

                        <div className={styles.Panel}>
                            <button onClick={onClose}>No</button>
                            <button
                                onClick={() => {
                                    const data = {
                                        name: nameRef.current.value,
                                        url: urlRef.current.value,
                                        is_link: isLinkRef.current.checked,
                                        type: this.props.projects.mode,
                                    };
                                    fetch("api/category/create", {
                                        method: 'POST',
                                        body: JSON.stringify(data),
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    }).then(resp => resp.json()).then(data => {
                                        if (!data.status) {
                                            alert("Something error");
                                        } else {
                                            this.loadData();
                                        }
                                    });

                                    onClose();
                                }}
                            >
                                Create it!
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        projects: state.projects
    }
}

function mapDispatchToProps(dispatch) {
    return {
        goToCategoryId: (set_title, set_id) => dispatch({type: 'goToCategoryId', title: set_title, id: set_id})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOfCategories);
