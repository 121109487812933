import React from "react";
import styles from './Header.module.css';
import Menu from "./Menu/Menu";

const Header = (props) => (
    <header className={styles.Main}>
        <section className={styles.Sec}>
            <h2 className={styles.Title}>БК БУДСЕРВIСГРУП</h2>
            <div className={styles.Line}></div>
        </section>
        <Menu/>
    </header>
);

export default Header;
