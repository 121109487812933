import React from 'react';
import styles from "./main.module.css";
import {connect} from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class main extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            statistic: [
                {num: 0},
                {num: 0},
                {num: 0},
                {num: 0}
            ],
            secIntro: {
                header: {
                    ru: "Строительная компания",
                    ua: "Будiвельна компанiя"
                },
                description: {
                    ru: "Строительство, реконструкция, капитальный ремонт объектов промышленного, социально-культурного и жилого назначения.",
                    ua: "Будівництво, реконструкція, капітальний ремонт об’єктів промислового, соціально-культурного та житлового призначення."
                },
                stat: {
                    i1: {
                        ru: "проектов",
                        ua: "проектiв"
                    },
                    i2: {
                        ru: "клиентов",
                        ua: "клiєнтiв"
                    },
                    i3: {
                        ru: "лицензий",
                        ua: "ліцензій"
                    },
                    i4: {
                        ru: "лет опыта",
                        ua: "рокiв досвiду"
                    }
                }
            },
            secAboutUs: {
                header: {
                    ru: "О нас",
                    ua: "Про нас"
                },
                description: {
                    ru: "ООО «СК БУДСЕРВИСГРУПП» было организовано в 2007 году и является генподрядной организацией с многолетним опытом строительства объектов различного типа, о чем свидетельствуют проекты, реализованные специалистами предприятия на территории Украины.",
                    ua: "ТОВ «БК БУДСЕРВІСГРУП» було організована в 2007 році і є генпідрядною організацією з багаторічним досвідом будівництва об’єктів різного типу, про що свідчать проекти, реалізовані фахівцями підприємства на території України."
                }
            },
            sec1: {
                header: {
                    ru: "Широкий спектр услуг",
                    ua: "Широкий спектр послуг"
                },
                description: {
                    ru: "Основной вид деятельности - строительство, реконструкция, капитальный ремонт объектов промышленного, социально-культурного и жилого назначения.",
                    ua: "Основний вид діяльності — будівництво, реконструкція, капітальний ремонт об’єктів промислового, соціально-культурного та житлового призначення."
                }
            },
            sec2: {
                header: {
                    ru: "Cвоя техника",
                    ua: "Своя технiка"
                },
                description: {
                    ru: "На балансе компании есть все необходимое оборудование, строительные механизмы и техника для строительства зданий и сооружений в любые технологические сроки.",
                    ua: "На балансі компанії є все необхідне обладнання, будівельні механізми і техніка для зведення будівель і споруд в будь-які технологічні терміни."
                }
            },
            sec3: {
                header: {
                    ru: "Профессиональный подход",
                    ua: "Професійний підхід"
                },
                description: {
                    ru: "Специалисты нашего предприятия предоставят Вам необходимую информацию, предложат образцы материалов, которые мы применяем, и окажут помощь в решении ваших проблем в строительной отрасли.",
                    ua: "Фахівці нашого підприємства нададуть Вам необхідну інформацію, запропонують зразки матеріалів, які ми застосовуємо, і нададуть допомогу у вирішенні ваших проблем в будівельній галузі."
                }
            },
            secProjects: {
                header: {
                    ru: "Галерея проектов",
                    ua: "Галерея проектiв"
                },
                description: {
                    ru: "Посмотрите наши проекты и убедитесь сами!",
                    ua: "Перегляньте нашi проекти та переконайтесь самi!"
                },
                button: {
                    ru: "Посмотреть",
                    ua: "Переглянути"
                }
            }
        };
    }

    render() {
        return (
            <div className={styles.Main}>
                <section className={styles.BudService}>
                    <h2>{this.props.lang === "ru" ? this.state.secIntro.header.ru : this.state.secIntro.header.ua}</h2>
                    <p>
                        {this.props.lang === "ru" ? this.state.secIntro.description.ru : this.state.secIntro.description.ua}
                    </p>
                    <div className={styles.Stat}>
                        <div className={styles.Item} onClick={this.props.auth ? this.changeNum.bind(this, "projects") : null}>
                            <span className={styles.Num}>{this.state.statistic[0].num}</span>
                            <span className={styles.Text}>{this.props.lang === "ru" ? this.state.secIntro.stat.i1.ru : this.state.secIntro.stat.i1.ua}</span>
                        </div>
                        <div className={styles.Item} onClick={this.props.auth ? this.changeNum.bind(this, "customers") : null}>
                            <span className={styles.Num}>{this.state.statistic[1].num}</span>
                            <span className={styles.Text}>{this.props.lang === "ru" ? this.state.secIntro.stat.i2.ru : this.state.secIntro.stat.i2.ua}</span>
                        </div>
                        <div className={styles.Item} onClick={this.props.auth ? this.changeNum.bind(this, "licences") : null}>
                            <span className={styles.Num}>{this.state.statistic[2].num}</span>
                            <span className={styles.Text}>{this.props.lang === "ru" ? this.state.secIntro.stat.i3.ru : this.state.secIntro.stat.i3.ua}</span>
                        </div>
                        <div className={styles.Item} onClick={this.props.auth ? this.changeNum.bind(this, "years") : null}>
                            <span className={styles.Num}>{this.state.statistic[3].num}</span>
                            <span className={styles.Text}>{this.props.lang === "ru" ? this.state.secIntro.stat.i4.ru : this.state.secIntro.stat.i4.ua}</span>
                        </div>
                    </div>
                </section>
                <section className={styles.AboutUs}>
                    <div className={styles.Shadow}>
                        <div className={styles.Block}>
                            <h2>{this.props.lang === "ru" ? this.state.secAboutUs.header.ru : this.state.secAboutUs.header.ua}</h2>
                            <p>
                                {this.props.lang === "ru" ? this.state.secAboutUs.description.ru : this.state.secAboutUs.description.ua}
                            </p>
                        </div>
                    </div>
                </section>
                <section className={styles.Sec1+" "+styles.Sec}>
                    <div className={styles.Left}>
                        <h2>{this.props.lang === "ru" ? this.state.sec1.header.ru : this.state.sec1.header.ua}</h2>
                        <p>{this.props.lang === "ru" ? this.state.sec1.description.ru : this.state.sec1.description.ua}</p>
                    </div>
                    <div className={styles.Right}>
                        <img alt="" src="/content/Main/sec2.png" />
                    </div>
                </section>
                <section className={styles.Sec2+" "+styles.Sec}>
                    <div className={styles.Right}>
                        <img alt="" src="/content/Main/sec1.png" />
                    </div>
                    <div className={styles.Left}>
                        <h2>{this.props.lang === "ru" ? this.state.sec2.header.ru : this.state.sec2.header.ua}</h2>
                        <p>{this.props.lang === "ru" ? this.state.sec2.description.ru : this.state.sec2.description.ua}</p>
                    </div>
                </section>
                <section className={styles.Sec3}>
                    <h2>{this.props.lang === "ru" ? this.state.sec3.header.ru : this.state.sec3.header.ua}</h2>
                    <p>{this.props.lang === "ru" ? this.state.sec3.description.ru : this.state.sec3.description.ua}</p>
                    <img alt="" src="/content/Main/sec3.png" />
                </section>
                <section className={styles.Projects}>
                    <div className={styles.Shadow}>
                        <div className={styles.Block}>
                            <h2>{this.props.lang === "ru" ? this.state.secProjects.header.ru : this.state.secProjects.header.ua}</h2>
                            <p>{this.props.lang === "ru" ? this.state.secProjects.description.ru : this.state.secProjects.description.ua}</p>
                            <svg width="181" height="181" viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M139.5 103.5C139.5 115.96 134.499 127.913 125.593 136.729C116.686 145.545 104.603 150.5 92 150.5C79.3973 150.5 67.3138 145.545 58.407 136.729C49.5007 127.913 44.5 115.96 44.5 103.5" stroke="#2980B9" stroke-width="3"/>
                                <line x1="73.5" y1="140" x2="73.5" y2="46" stroke="white" stroke-width="3"/>
                                <line x1="107.5" y1="140" x2="107.5" y2="46" stroke="white" stroke-width="3"/>
                                <line x1="90.5" y1="145" x2="90.5" y2="29" stroke="white" stroke-width="3"/>
                                <line x1="73.0393" y1="47.1394" x2="90.0393" y2="30.1394" stroke="white" stroke-width="3"/>
                                <line x1="107.939" y1="47.0607" x2="90.9393" y2="30.0607" stroke="white" stroke-width="3"/>
                                <line x1="73.135" y1="100.775" x2="90.135" y2="88.7745" stroke="white" stroke-width="3"/>
                                <line x1="74.135" y1="87.7745" x2="91.135" y2="75.7745" stroke="white" stroke-width="3"/>
                                <path d="M107.202 66.7106C115.565 70.2799 122.462 76.5922 126.757 84.6069C131.052 92.6217 132.488 101.861 130.828 110.801C129.168 119.741 124.512 127.849 117.627 133.788C110.742 139.728 102.038 143.144 92.9514 143.474C83.8644 143.804 74.9362 141.028 67.6383 135.603C60.3405 130.179 55.1086 122.43 52.8049 113.634C50.5013 104.837 51.2634 95.5187 54.9658 87.2135C58.6681 78.9084 65.0896 72.1126 73.1718 67.9461" stroke="white" stroke-width="3"/>
                            </svg>
                            <button className="defaultBtn">{this.props.lang === "ru" ? this.state.secProjects.button.ru : this.state.secProjects.button.ua}</button>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    loadData = () => {
        fetch('api/statistic/select').then(resp => resp.json()).then((data) => {
            this.setState({
                statistic: data
            });
        });
    };

    componentDidMount() {
        this.loadData();
    }

    changeNum = (field) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                const value = React.createRef();

                return (
                    <div className={styles.Update}>
                        <h1>Update the value</h1>
                        <input ref={value} placeholder="Value"/>

                        <div className={styles.Panel}>
                            <button onClick={onClose}>Cancel</button>
                            <button
                                onClick={() => {
                                    const data = {
                                        category: field,
                                        num: value.current.value
                                    };
                                    fetch("api/statistic/update", {
                                        method: 'POST',
                                        body: JSON.stringify(data),
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    }).then(resp => resp.json()).then(data => {
                                        if (!data.status) {
                                            alert("Something error");
                                        } else {
                                            this.loadData();
                                        }
                                    });

                                    onClose();
                                }}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                );
            }
        });
    };
}


function mapStateToProps(state) {
    return {
        auth: state.auth,
        lang: state.lang
    }
}

export default connect(mapStateToProps)(main);
