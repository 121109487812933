import React from 'react';
import styles from './ViewProject.module.css';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class ViewProject extends React.Component {
    some = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            photo: "",
            images: [],
            description: ""
        }
    }

    render() {
        return (
            <main className={styles.Main}>
               <div className={styles.Galery}>
                   <div className={styles.background}>
                       <img alt="" className={styles.back} src={this.state.photo}/>
                       <img alt="" className={styles.front} src={this.state.photo}/>
                   </div>
                   <div className={styles.ListOfPhoto}>
                       {
                           this.state.images.map((item, index) => {
                               return (
                                   <img alt="" className={styles.delete} key={index} src={item.src} onClick={!this.props.auth ? this.changePhoto.bind(this, item.src) : this.deletePhoto.bind(this, item.src)}/>
                               );
                           })
                       }
                       {
                           this.props.auth ?
                               <label>
                                   <input onChange={this.loadPhoto.bind(this)} className="none" ref={this.some} type="file"/>
                                   <img alt="" src="/content/Icon/plus.png" />
                               </label>
                               : null
                       }
                   </div>
               </div>
                <p>
                    {this.state.description}
                </p>
                {
                    this.props.auth ?
                        <img alt="" className={styles.delete} onClick={this.deleteProject.bind(this)} src="/content/Icon/delete.png"/>
                        : null
                }
            </main>
        );
    }

    loadData = () => {
        fetch('api/projects/photos/select', {
            method: 'POST',
            body: JSON.stringify({
                'id': this.props.projects.project.id
            })
        }).then(resp => resp.json()).then((data) => {
            if (data[0] !== undefined) {
                this.setState({
                    photo: data[0].src,
                    images: data
                })
            }
        });

        fetch('api/project/get', {
            method: 'POST',
            body: JSON.stringify({
                id: this.props.projects.project.id
            })
        }).then(resp => resp.json()).then((data) => {
            this.setState({
                description: data[0].description
            })
        });
    };

    componentDidMount() {
        this.loadData();
    }

    loadPhoto = () => {
        const fd = new FormData();
        fd.append('id', this.props.projects.project.id);
        fd.append('picture', this.some.current.files[0]);

        fetch('api/photo/insert', {
            method: 'POST',
            body: fd
        }).then(resp => resp.json()).then((data) => {
            if (!data.status)
                alert("Some error");
            else {
                this.loadData();
            }
        });
    };

    changePhoto = (src) => {
        this.setState({
            photo: src
        });
    };

    deletePhoto = (src) => {
        confirmAlert({
            title: 'Confirm this action',
            message: 'Are you sure to delete this image?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        fetch('api/photo/delete', {
                            method: 'POST',
                            body: JSON.stringify({
                                src: src
                            })
                        }).then(resp => resp.json()).then((data) => {
                            if (!data)
                                alert("some error");
                            else
                                this.loadData();
                        });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    deleteProject = () => {
        fetch('api/project/delete', {
            method: 'POST',
            body: JSON.stringify({
                id: this.props.projects.project.id
            })
        }).then(resp => resp.text()).then((data) => {
            this.props.hideProjectsWindow();
        });
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        projects: state.projects
    }
}

function mapDispatchToProps(dispatch) {
    return {
        hideProjectsWindow: () => dispatch({type: 'hideProjectsWindow'})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewProject);
