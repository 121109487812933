import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as ReactRouter} from 'react-router-dom';
import {Provider} from 'react-redux'
import {createStore} from 'redux';
import reducer from "./redux/reducer";

const store = createStore(reducer);

const Application = (
    <Provider store={store}>
        <ReactRouter>
            <App />
        </ReactRouter>
    </Provider>
);


ReactDOM.render(Application, document.getElementById('root'));