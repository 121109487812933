import React from 'react';
import styles from './Preloader.module.css';

const Preloader = React.forwardRef((props, ref) => (
    <div className={styles.Container} ref={ref}>
        <div className={styles.Preload}>
            <div className={styles.Icon}>
                <svg width="181" height="181" viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1" fill="white">
                        <path className={styles.c1+" "+styles.general} d="M73.8846 64.2148C64.9839 67.9131 57.626 74.5601 53.0456 83.0407C48.4652 91.5212 46.941 101.319 48.7288 110.79C50.5166 120.261 55.5075 128.83 62.8641 135.057C70.2207 141.284 79.4953 144.792 89.1317 144.991C98.768 145.19 108.18 142.069 115.787 136.151C123.395 130.233 128.736 121.879 130.914 112.49C133.092 103.101 131.974 93.248 127.748 84.5854C123.522 75.9228 116.445 68.9772 107.705 64.9142L106.641 67.2034C114.856 71.0222 121.507 77.5503 125.479 85.6923C129.451 93.8342 130.502 103.095 128.455 111.919C126.408 120.744 121.388 128.596 114.237 134.159C107.087 139.721 98.241 142.654 89.1839 142.467C80.1267 142.28 71.4096 138.983 64.4951 133.13C57.5807 127.277 52.8898 119.224 51.2095 110.322C49.5292 101.42 50.9618 92.2111 55.2669 84.2404C59.5719 76.2696 66.4875 70.022 74.8532 66.5461L73.8846 64.2148Z"/>
                    </mask>
                    <path d="M73.8846 64.2148C64.9839 67.9131 57.626 74.5601 53.0456 83.0407C48.4652 91.5212 46.941 101.319 48.7288 110.79C50.5166 120.261 55.5075 128.83 62.8641 135.057C70.2207 141.284 79.4953 144.792 89.1317 144.991C98.768 145.19 108.18 142.069 115.787 136.151C123.395 130.233 128.736 121.879 130.914 112.49C133.092 103.101 131.974 93.248 127.748 84.5854C123.522 75.9228 116.445 68.9772 107.705 64.9142L106.641 67.2034C114.856 71.0222 121.507 77.5503 125.479 85.6923C129.451 93.8342 130.502 103.095 128.455 111.919C126.408 120.744 121.388 128.596 114.237 134.159C107.087 139.721 98.241 142.654 89.1839 142.467C80.1267 142.28 71.4096 138.983 64.4951 133.13C57.5807 127.277 52.8898 119.224 51.2095 110.322C49.5292 101.42 50.9618 92.2111 55.2669 84.2404C59.5719 76.2696 66.4875 70.022 74.8532 66.5461L73.8846 64.2148Z" stroke="white" strokeWidth="6" mask="url(#path-1-inside-1)"/>
                    <path d="M138.5 102.5C138.5 108.803 137.258 115.045 134.846 120.869C132.434 126.692 128.898 131.984 124.441 136.441C119.984 140.898 114.692 144.434 108.869 146.846C103.045 149.258 96.8034 150.5 90.5 150.5C84.1965 150.5 77.9548 149.258 72.1312 146.846C66.3076 144.434 61.0161 140.898 56.5589 136.441C52.1017 131.984 48.566 126.692 46.1538 120.869C43.7416 115.045 42.5 108.803 42.5 102.5" stroke="#2980B9" strokeWidth="3"/>
                    <line className={styles.l1+" "+styles.general} x1="73.5" y1="140" x2="73.5" y2="46" stroke="white" strokeWidth="3"/>
                    <line className={styles.l2+" "+styles.general} x1="107.5" y1="140" x2="107.5" y2="46" stroke="white" strokeWidth="3"/>
                    <line className={styles.l3+" "+styles.general} x1="90.5" y1="145" x2="90.5" y2="29" stroke="white" strokeWidth="3"/>
                    <line className={styles.l4+" "+styles.general} x1="73.0393" y1="47.1394" x2="90.0393" y2="30.1394" stroke="white" strokeWidth="3"/>
                    <line className={styles.l5+" "+styles.general} x1="107.939" y1="47.0607" x2="90.9393" y2="30.0607" stroke="white" strokeWidth="3"/>
                    <line className={styles.l6+" "+styles.general} x1="73.135" y1="100.775" x2="90.135" y2="88.7745" stroke="white" strokeWidth="3"/>
                    <line className={styles.l7+" "+styles.general} x1="74.135" y1="87.7745" x2="91.135" y2="75.7745" stroke="white" strokeWidth="3"/>
                </svg>
            </div>
            <div className={styles.Title}>БК БУДСЕРВІСГРУП</div>
            <div className={styles.Line}></div>
        </div>
    </div>
));

export default Preloader;
