import React from 'react';
import {connect} from 'react-redux';
import styles from './Menu.module.css';
import {Link} from "react-router-dom";

class Menu extends React.Component {
    nav = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            main: {
                ua: "Головна",
                ru: "Главная"
            },
            services: {
                ua: "Послуги",
                ru: "Услуги"
            },
            projects: {
                ua: "Проекти",
                ru: "Проекты"
            },
            licences: {
                ua: "Лiцензiї",
                ru: "Лицензии"
            },
            achievements: {
                ua: "Досягнення",
                ru: "Достижения"
            }
        }
    }

    render() {
        return(
            <nav className={styles.Main} ref={this.nav}>
                <Link to="/main">
                    <svg width="181" height="181" viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M139.5 103.5C139.5 115.96 134.499 127.913 125.593 136.729C116.686 145.545 104.603 150.5 92 150.5C79.3973 150.5 67.3138 145.545 58.407 136.729C49.5007 127.913 44.5 115.96 44.5 103.5" stroke="#2980B9" stroke-width="3"/>
                        <line x1="73.5" y1="140" x2="73.5" y2="46" stroke="white" stroke-width="3"/>
                        <line x1="107.5" y1="140" x2="107.5" y2="46" stroke="white" stroke-width="3"/>
                        <line x1="90.5" y1="145" x2="90.5" y2="29" stroke="white" stroke-width="3"/>
                        <line x1="73.0393" y1="47.1394" x2="90.0393" y2="30.1394" stroke="white" stroke-width="3"/>
                        <line x1="107.939" y1="47.0607" x2="90.9393" y2="30.0607" stroke="white" stroke-width="3"/>
                        <line x1="73.135" y1="100.775" x2="90.135" y2="88.7745" stroke="white" stroke-width="3"/>
                        <line x1="74.135" y1="87.7745" x2="91.135" y2="75.7745" stroke="white" stroke-width="3"/>
                        <path d="M107.202 66.7106C115.565 70.2799 122.462 76.5922 126.757 84.6069C131.052 92.6217 132.488 101.861 130.828 110.801C129.168 119.741 124.512 127.849 117.627 133.788C110.742 139.728 102.038 143.144 92.9514 143.474C83.8644 143.804 74.9362 141.028 67.6383 135.603C60.3405 130.179 55.1086 122.43 52.8049 113.634C50.5013 104.837 51.2634 95.5187 54.9658 87.2135C58.6681 78.9084 65.0896 72.1126 73.1718 67.9461" stroke="white" stroke-width="3"/>
                    </svg>
                </Link>
                <Link to="/main" onClick={this.handleClick.bind(this, 1)} className={(this.props.nav === 1) ? styles.selected : null}>{this.props.lang === 'ua' ? this.state.main.ua : this.state.main.ru}</Link>
                <Link to="/services" onClick={this.handleClick.bind(this, 2)} className={(this.props.nav === 2) ? styles.selected : null}>{this.props.lang === 'ua' ? this.state.services.ua : this.state.services.ru}</Link>
                <Link to="/projects" onClick={this.handleClick.bind(this, 3)} className={(this.props.nav === 3) ? styles.selected : null}>{this.props.lang === 'ua' ? this.state.projects.ua : this.state.projects.ru}</Link>
                <Link to="/licences" onClick={this.handleClick.bind(this, 4)} className={(this.props.nav === 4) ? styles.selected : null}>{this.props.lang === 'ua' ? this.state.licences.ua : this.state.licences.ru}</Link>
                <Link to="/achievements" onClick={this.handleClick.bind(this, 5)} className={(this.props.nav === 5) ? styles.selected : null}>{this.props.lang === 'ua' ? this.state.achievements.ua : this.state.achievements.ru}</Link>
                <a className={styles.Lang} onClick={this.changeLang.bind(this)}>{this.props.lang}</a>
            </nav>
        );
    }

    changeLang = () => {
        if (this.props.lang === 'ua')
            this.props.switchLang('ru');
        else {
            this.props.switchLang('ua');
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleClick = (num) => {
        window.scrollTo(0, document.documentElement.clientHeight);
        this.props.goTo(num);
    };

    handleScroll = (e) => {
        const x = window.pageYOffset;

        if (x < 100) {
            this.nav.current.style.bottom = -x + "px";
            this.nav.current.style.top = "auto";
        }

        if (x >= 150 && x <= 250) {
            this.nav.current.style.bottom = "auto";
            this.nav.current.style.top = (x - 250) + "px";
        }

        if (x >= 250){
            this.nav.current.style.bottom = "auto";
            this.nav.current.style.top = 0 + "px";
        }
    }
}

export default connect(
    state => (state),
        dispatch => ({
            goTo: (val) => (
                dispatch({type: 'goTo', payload: val})
            ),
            switchLang: (val) => (
                dispatch({type: 'switchLang', payload: val})
            )
        })
)(Menu);
