import React from 'react';
import styles from "./services.module.css";
import {connect} from "react-redux";

class services extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sec0: {
                header: {
                    ru: "Какие услуги мы предоставляем?",
                    ua: "Якi послуги ми надаємо ?"
                },
                s4: {
                    ru: "Генподрядные работы и Субподрядные работы",
                    ua: "Генпідрядні роботи та Субпідрядні роботи"
                }
            },
            sec1: {
                header: {
                    ru: "Жилищное строительство",
                    ua: "Житлове будівництво"
                },
                description: {
                    ru: "Жилищное строительство одно из направлений первоклассной деятельности БК БУДСЕРВИСГРУПП. Мы имеем значительный опыт в проектировании и строительстве жилых зданий различного назначения. В нашей специализации, как многоэтажные дома, так и коттеджи, частные жилые дома, объекты государственного и общественного назначения. Наши строительные бригады состоят из исключительных специалистов, обладающих значительным опытом работы. Мы не стоим на месте, постоянно улучшая свои услуги и совершенствуя практические навыки. Поэтому выполняем жилищное строительство качественно и профессионально. В Ваших планах жилищное строительство? Смело обращайтесь в нашу компанию! Мы гарантируем Вам максимально эффективную организацию строительных процессов, для получения своевременного и желаемого результата!",
                    ua: "Житлове будівництво один із напрямів першокласної діяльності БК БУДСЕРВІСГРУП. Ми маємо значний досвід у проектуванні та будівництві житлових будинків різного призначення. В нашій спеціалізації, як багатоповерхові будинки, так і котеджі, приватні житлові будинки, об’єкти державного і громадського призначення. Наші будівельні бригади складаються з виняткових фахівців, які володіють значним досвідом роботи. Ми не стоїмо на місці, постійно покращуючи свої послуги і удосконалюючи практичні навички. Тому виконуємо житлове будівництво якісно і професійно. В Ваших планах житлове будівництво? Сміливо звертайтеся в нашу компанію! Ми гарантуємо Вам максимально ефективну організацію будівельних процесів, для отримання своєчасного і бажаного результату!"
                }
            },
            sec2: {
                header: {
                    ru: "Инфраструктурное строительство",
                    ua: "Інфраструктурне будівництво"
                },
                description: {
                    ru: "БК БУДСЕРВИСГРУПП выполняет полный комплекс работ по строительству и реконструкции инфраструктурных объектов «под ключ» (в том числе особо опасных, технически сложных и уникальных объектов, объектов обороны и безопасности, других объектов, сведения о которых составляют государственную тайну). Мы создаем объекты, которые улучшают благополучие жизни населения и помогают в финансово-экономическом развитии территорий. Кроме стандартных, проверенных временем проектных решений, мы применяем передовые разработки в области инфраструктурного строительства, позволяет нам с успехом проектировать и строить объекты, которые соответствуют современным мировым требованиям к удобству, эффективности и безопасности. Для каждого объекта, мы предлагаем к реализации только наиболее подходящее и проверенное решение, оправдано с технической, экологической и экономической точки зрения, а также с учетом индивидуальных требований и особенностей.",
                    ua: "БК БУДСЕРВІСГРУП виконує повний комплекс робіт з будівництва та реконструкції інфраструктурних об’єктів «під ключ» (в тому числі особливо небезпечних, технічно складних і унікальних об’єктів, об’єктів оборони та безпеки, інших об’єктів, відомості про які становлять державну таємницю). Ми створюємо об’єкти які покращують благополуччя життя населення і допомагають в фінансово-економічному розвитку територій. Крім стандартних, перевірених часом проектних рішень, ми застосовуємо передові розробки в області інфраструктурного будівництва, що дозволяє нам з успіхом проектувати і будувати об’єкти, які відповідають сучасним світовим вимогам до зручності, ефективності та безпеки. Для кожного об’єкта, ми пропонуємо до реалізації тільки найбільш підходяще і перевірене рішення, виправдане з технічної, екологічної і економічної точки зору, а також з урахуванням індивідуальних вимог і особливостей."
                }
            },
            sec3: {
                header: {
                    ru: "Промышленное строительство",
                    ua: "Промислове будівництво"
                },
                description: {
                    ru: "Мы выполняем строительство с реализацией особых пожеланий Заказчика, соблюдая при этом строительные стандарты и нормы. Наша система контроля качества гарантирует безопасность и долговечность, построенных нами объектов. Наш опыт позволяет управлять реконструкцией промышленных объектов без остановки технологического процесса и в условиях краткости строительной площадки.",
                    ua: "Ми виконуємо промислове будівництво з реалізацією особливих побажань Замовника, дотримуючись при цьому будівельні стандарти і норми. Наша система контролю якості гарантує безпеку і довговічність, побудованих нами об’єктів. Наш досвід дозволяє керувати реконструкцією промислових об’єктів без зупинки технологічного процесу і в умовах стислості будівельного майданчика."
                },
                list: {
                    li1: {
                        ru: "Монолитные железобетонные работы (монолитное строительство);",
                        ua: "Монолітні залізобетонні роботи (монолітне будівництво);"
                    },
                    li2: {
                        ru: "Монтаж и пуско-наладка технологического оборудования;",
                        ua: "Монтаж і пуско-наладка технологічного обладнання;"
                    },
                    li3: {
                        ru: "Монтаж и производство металлоконструкций;",
                        ua: "Монтаж і виробництво металоконструкцій;"
                    },
                    li4: {
                        ru: "Монтаж инженерных сетей;",
                        ua: "Монтаж інженерних мереж;"
                    },
                    li5: {
                        ru: "Промышленный демонтаж зданий и сооружений;",
                        ua: "Промисловий демонтаж будівель і споруд;"
                    },
                    li6: {
                        ru: "Проектирование промышленных объектов, генпроектирование;",
                        ua: "Проектування промислових об'єктів, генпроектування;"
                    },
                    li7: {
                        ru: "Земляные работы;",
                        ua: "Земляні роботи;"
                    },
                    li8: {
                        ru: "Подготовительные работы.",
                        ua: "Підготовчі роботи."
                    },
                },
                footer: {
                    ru: "БК БУДСЕРВИСГРУПП выполняет комплекс услуг в сфере промышленного строительство от создания концепции до сдачи в эксплуатацию объекта. Учитываем индивидуальные, уникальные требования Заказчика. Оптимизируем затраты клиента на стадии эксплуатации сооружения.",
                    ua: "БК БУДСЕРВІСГРУП виконує комплекс послуг в сфері промислового будівництво від створення концепції до здачі в експлуатацію об’єкта. Враховуємо індивідуальні, унікальні вимоги Замовника. Оптимізуємо витрати клієнта на стадії експлуатації споруди."
                }
            },
            sec4: {
                first: {
                    header: {
                        ru: "Генподрядные работы",
                        ua: "Генпідрядні роботи"
                    },
                    description: {
                        ru: "БК БУДСЕРВИСГРУПП выполняет широкий спектр генподрядных работ, так как имеем современные механизмы, спецтехнику и производственные ресурсы. Самостоятельно выполняем самые сложные задачи, реализуем широко профильные заказ. Применяем современные строительные технологии, квалифицированный кадровый состав позволяют нашей компании осуществлять профессиональные услуги с выполнением функций генподрядчика. Техническое консультирование при проектировании и строительстве, а так же все виды проектных работ:",
                        ua: "БК БУДСЕРВІСГРУП виконує широкий спектр генпідрядних робіт, так як маємо сучасні механізми, спецтехніку та виробничі ресурси. Самостійно виконуємо найскладніші завдання, реалізуємо широко профільні замовлення. Застосовуємо сучасні будівельні технології, кваліфікований кадровий склад дозволяють нашій компанії здійснювати професійні послуги з виконанням функцій генпідрядника. Технічне консультування при проектуванні і будівництві, а так само всі види проектних робіт:"
                    },
                    list: {
                        li1: {
                            ru: "Архитектурное и строительное проектирование;",
                            ua: "Архітектурне та будівельне проектування;"
                        },
                        li2: {
                            ru: "Конструирование несущих конструкций;",
                            ua: "Конструювання несучих конструкцій;"
                        },
                        li3: {
                            ru: "Проектирование внутренних и подземных инженерных сетей, систем и сооружений;",
                            ua: "Проектування внутрішніх і підземних інженерних мереж, систем і споруд;"
                        },
                        li4: {
                            ru: "Строительно-монтажные работы разного уровня сложности;",
                            ua: "Будівельно-монтажні роботи різного рівня складності;"
                        },
                        li5: {
                            ru: "Демонтажные и земляные работы;",
                            ua: "Демонтажні та земляні роботи;"
                        },
                        li6: {
                            ru: "Строительство инженерных коммуникаций;",
                            ua: "Будівництво інженерних комунікацій;"
                        },
                        li7: {
                            ru: "Реставрационные работы.",
                            ua: "Реставраційні роботи."
                        },
                    },
                    footer: {
                        ru: "Компания, как генподрядчик берет на себя обязательства по руководству и организации работ, тем самым, освобождая заказчика от лишних хлопот и отвечает за весь объект в целом.",
                        ua: "Компанія, як генпідрядник бере на себе зобов’язання по керівництву і організації робіт, тим самим, звільняючи замовника від зайвих клопотів і відповідає за весь об’єкт в цілому."
                    }
                },

                second: {
                    header: {
                        ru: "Субподрядные работы",
                        ua: "Субпідрядні роботи"
                    },
                    description: {
                        ru: "БК БУДСЕРВИСГРУПП является подрядчиком на выполнение строительно-монтажных работ по инженерным коммуникациям, а также комплектует объекты промышленного, жилищного или социального секторов строительства. Вместе с тем, мы предлагаем широкий комплекс общестроительных и подрядных работ:",
                        ua: "БК БУДСЕРВІСГРУП є підрядником на виконання будівельно-монтажних робіт з інженерних комунікацій, а також комплектує об’єкти промислового, житлового або соціального секторів будівництва. Разом з тим, ми пропонуємо широкий комплекс загальнобудівельних та підрядних робіт:"
                    },
                    list: {
                        li1: {
                            ru: "Демонтажные работы.",
                            ua: "Демонтажні роботи."
                        },
                        li2: {
                            ru: "Монтажные работы.",
                            ua: "Монтажні роботи."
                        },
                        li3: {
                            ru: "Черновые и чистовые отделочные работы.",
                            ua: "Чорнові і чистові оздоблювальні роботи."
                        },
                        li4: {
                            ru: "Капитальный и частичный ремонт помещений, офисов, магазинов, кафе, квартир, домов.",
                            ua: "Капітальний і частковий ремонт приміщень, офісів, магазинів, кафе, квартир, будинків."
                        },
                        li5: {
                            ru: "Сварка, сварочные работы.",
                            ua: "Зварювання, зварювальні роботи."
                        },
                        li6: {
                            ru: "Работы по прокладке магистральных трубопроводов систем горячего и холодного водоснабжения, канализации и теплоснабжения.",
                            ua: "Роботи з прокладання магістральних трубопроводів систем гарячого і холодного водопостачання, каналізації і теплопостачання."
                        },
                        li7: {
                            ru: "Сантехнические работы (канализация, отопление).",
                            ua: "Сантехнічні роботи (каналізація, опалення)."
                        },
                        li8: {
                            ru: "Кондиционирование и вентиляция.",
                            ua: "Кондиціонування та вентиляція."
                        },
                        li9: {
                            ru: "Предоставление услуг по техническому обслуживанию, эксплуатации и текущего ремонта помещений, инженерных и коммунальных систем.",
                            ua: "Надання послуг з технічного обслуговування, експлуатації та поточного ремонту приміщень, інженерних і комунальних систем."
                        },
                    }
                }
            },
            sec5: {
                header: {
                    ru: "Изготовление металлоконструкций",
                    ua: "Виготовлення металоконструкцій"
                },
                description: {
                    ru: "БК БУДСЕРВИСГРУПП выполняет полный комплекс работ по строительству и реконструкции инфраструктурных объектов «под ключ» (в том числе особо опасных, технически сложных и уникальных объектов, объектов обороны и безопасности, других объектов, сведения о которых составляют государственную тайну). Мы создаем объекты, которые улучшают благополучие жизни населения и помогают в финансово-экономическом развитии территорий. Кроме стандартных, проверенных временем.",
                    ua: "БК БУДСЕРВІСГРУП виконує повний комплекс робіт з будівництва та реконструкції інфраструктурних об’єктів «під ключ» (в тому числі особливо небезпечних, технічно складних і унікальних об’єктів, об’єктів оборони та безпеки, інших об’єктів, відомості про які становлять державну таємницю). Ми створюємо об’єкти які покращують благополуччя життя населення і допомагають в фінансово-економічному розвитку територій. Крім стандартних, перевірених часом."
                }
            },
            button: {
                ru: "Посмотреть",
                ua: "Переглянути"
            }
        };
    }

    render() {
        return (
            <div className={styles.Main}>
                <section className={styles.Sec1}>
                    <h2>{this.props.lang === "ru" ? this.state.sec0.header.ru : this.state.sec0.header.ua}</h2>
                    <div className={styles.HorLayout}>
                        <div className={styles.Left}>
                            <div className={styles.Layout}>
                                <div className={styles.Item}>
                                    <a href="#sec1">{this.props.lang === "ru" ? this.state.sec1.header.ru : this.state.sec1.header.ua}</a>
                                </div>
                                <div className={styles.Arrow}></div>
                            </div>
                            <div className={styles.Layout}>
                                <div className={styles.Item}>
                                    <a href="#sec2">{this.props.lang === "ru" ? this.state.sec2.header.ru : this.state.sec2.header.ua}</a>
                                </div>
                                <div className={styles.Arrow}></div>
                            </div>
                            <div className={styles.Layout}>
                                <div className={styles.Item}>
                                    <a href="#sec5">{this.props.lang === "ru" ? this.state.sec5.header.ru : this.state.sec5.header.ua}</a>
                                </div>
                                <div className={styles.Arrow}></div>
                            </div>
                        </div>
                        <div className={styles.Root}></div>
                        <div className={styles.Right}>
                            <div className={styles.Layout}>
                                <div className={styles.Arrow}></div>
                                <div className={styles.Item}>
                                    <a href="#sec3">{this.props.lang === "ru" ? this.state.sec3.header.ru : this.state.sec3.header.ua}</a>
                                </div>
                            </div>
                            <div className={styles.Layout}>
                                <div className={styles.Arrow}></div>
                                <div className={styles.Item}>
                                    <a href="#sec4">{this.props.lang === "ru" ? this.state.sec0.s4.ru : this.state.sec0.s4.ua}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.PhoneOnly}>
                        <div className={styles.Left}>
                            <div className={styles.Layout}>
                                <div className={styles.Item}>
                                    <a href="#sec1">{this.props.lang === "ru" ? this.state.sec1.header.ru : this.state.sec1.header.ua}</a>
                                </div>
                                <div className={styles.Arrow}></div>
                            </div>
                            <div className={styles.Layout}>
                                <div className={styles.Item}>
                                    <a href="#sec2">{this.props.lang === "ru" ? this.state.sec2.header.ru : this.state.sec2.header.ua}</a>
                                </div>
                                <div className={styles.Arrow}></div>
                            </div>
                            <div className={styles.Layout}>
                                <div className={styles.Item}>
                                    <a href="#sec5">{this.props.lang === "ru" ? this.state.sec5.header.ru : this.state.sec5.header.ua}</a>
                                </div>
                                <div className={styles.Arrow}></div>
                            </div>
                            <div className={styles.Layout}>
                                <div className={styles.Item}>
                                    <a href="#sec3">{this.props.lang === "ru" ? this.state.sec3.header.ru : this.state.sec3.header.ua}</a>
                                </div>
                                <div className={styles.Arrow}></div>
                            </div>
                            <div className={styles.Layout}>
                                <div className={styles.Item}>
                                    <a href="#sec4">{this.props.lang === "ru" ? this.state.sec0.s4.ru : this.state.sec0.s4.ua}</a>
                                </div>
                                <div className={styles.Arrow}></div>
                            </div>
                        </div>
                        <div className={styles.Root}></div>
                    </div>
                </section>
                <section id="sec1" className={styles.Sec+" "+styles.Sec2}>
                    <h2>{this.props.lang === "ru" ? this.state.sec1.header.ru : this.state.sec1.header.ua}</h2>
                    <img alt="" src="/content/Services/sec2.png"/>
                    <p>{this.props.lang === "ru" ? this.state.sec1.description.ru : this.state.sec1.description.ua}</p>
                    <button className="defaultBtn">{this.props.lang === "ru" ? this.state.button.ru : this.state.button.ua}</button>
                </section>
                <section id="sec2" className={styles.Sec+" "+styles.Sec3}>
                    <h2>{this.props.lang === "ru" ? this.state.sec2.header.ru : this.state.sec2.header.ua}</h2>
                    <img alt="" src="/content/Services/sec3.png"/>
                    <p>{this.props.lang === "ru" ? this.state.sec2.description.ru : this.state.sec2.description.ua}</p>
                </section>
                <section id="sec3" className={styles.Sec+" "+styles.Sec4}>
                    <h2>{this.props.lang === "ru" ? this.state.sec3.header.ru : this.state.sec3.header.ua}</h2>
                    <p>{this.props.lang === "ru" ? this.state.sec3.description.ru : this.state.sec3.description.ua}</p>
                    <div className={styles.Countainer}>
                        <img alt="" src="/content/Services/sec4.png"/>
                        <ol>
                            <li> {this.props.lang === "ru" ? this.state.sec3.list.li1.ru : this.state.sec3.list.li1.ua}</li>
                            <li> {this.props.lang === "ru" ? this.state.sec3.list.li2.ru : this.state.sec3.list.li2.ua}</li>
                            <li> {this.props.lang === "ru" ? this.state.sec3.list.li3.ru : this.state.sec3.list.li3.ua} </li>
                            <li> {this.props.lang === "ru" ? this.state.sec3.list.li4.ru : this.state.sec3.list.li4.ua} </li>
                            <li> {this.props.lang === "ru" ? this.state.sec3.list.li5.ru : this.state.sec3.list.li5.ua}</li>
                            <li> {this.props.lang === "ru" ? this.state.sec3.list.li6.ru : this.state.sec3.list.li6.ua}</li>
                            <li> {this.props.lang === "ru" ? this.state.sec3.list.li7.ru : this.state.sec3.list.li7.ua}</li>
                            <li> {this.props.lang === "ru" ? this.state.sec3.list.li8.ru : this.state.sec3.list.li8.ua}</li>
                        </ol>
                    </div>
                    <p>{this.props.lang === "ru" ? this.state.sec3.footer.ru : this.state.sec3.footer.ua}</p>
                </section>
                <section id="sec4" className={styles.Sec5}>
                    <div className={styles.countainer}>
                        <div className={styles.LeftSec}>
                            <h2>{this.props.lang === "ru" ? this.state.sec4.first.header.ru : this.state.sec4.first.header.ua}</h2>
                            <p>{this.props.lang === "ru" ? this.state.sec4.first.description.ru : this.state.sec4.first.description.ua}</p>
                            <ol>
                                <li>{this.props.lang === "ru" ? this.state.sec4.first.list.li1.ru : this.state.sec4.first.list.li1.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.first.list.li2.ru : this.state.sec4.first.list.li2.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.first.list.li3.ru : this.state.sec4.first.list.li3.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.first.list.li4.ru : this.state.sec4.first.list.li4.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.first.list.li5.ru : this.state.sec4.first.list.li5.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.first.list.li6.ru : this.state.sec4.first.list.li6.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.first.list.li7.ru : this.state.sec4.first.list.li7.ua}</li>
                            </ol>
                            <p>{this.props.lang === "ru" ? this.state.sec4.first.footer.ru : this.state.sec4.first.footer.ua}</p>
                        </div>
                        <div className={styles.MidSec}>
                            <div>
                                <img alt="" src="/content/Services/sec5.png"/>
                            </div>
                        </div>
                        <div className={styles.RightSec}>
                            <h2>{this.props.lang === "ru" ? this.state.sec4.second.header.ru : this.state.sec4.second.header.ua}</h2>
                            <p>{this.props.lang === "ru" ? this.state.sec4.second.description.ru : this.state.sec4.second.description.ua}</p>
                            <ol>
                                <li>{this.props.lang === "ru" ? this.state.sec4.second.list.li1.ru : this.state.sec4.second.list.li1.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.second.list.li2.ru : this.state.sec4.second.list.li2.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.second.list.li3.ru : this.state.sec4.second.list.li3.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.second.list.li4.ru : this.state.sec4.second.list.li4.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.second.list.li5.ru : this.state.sec4.second.list.li5.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.second.list.li6.ru : this.state.sec4.second.list.li6.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.second.list.li7.ru : this.state.sec4.second.list.li7.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.second.list.li8.ru : this.state.sec4.second.list.li8.ua}</li>
                                <li>{this.props.lang === "ru" ? this.state.sec4.second.list.li9.ru : this.state.sec4.second.list.li9.ua}</li>
                            </ol>
                        </div>
                    </div>
                </section>
                <section id="sec5" className={styles.Sec+" "+styles.Sec6}>
                    <h2>{this.props.lang === "ru" ? this.state.sec5.header.ru : this.state.sec5.header.ua}</h2>
                    <img alt="" src="/content/Services/sec3.png"/>
                    <p>{this.props.lang === "ru" ? this.state.sec5.description.ru : this.state.sec5.description.ua}</p>
                    <button className="defaultBtn">{this.props.lang === "ru" ? this.state.button.ru : this.state.button.ua}</button>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        lang: state.lang
    }
}

export default connect(mapStateToProps)(services);
