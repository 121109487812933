import React from 'react';
import styles from "./licences.module.css";
import {connect} from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class licences extends React.Component {
    some = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            licences: []
        }
    }

    render () {
        return (
            <div className={styles.Main}>
                {
                    this.state.licences.map((item, index) => {
                        return (
                            <div key={index}>
                                <img alt=""  src={item.src} onClick={this.props.auth ? this.deletePhoto.bind(this, item.src) : this.bigPhoto.bind(this, item.src)}/>
                            </div>
                        )
                    })
                }
                {
                    this.props.auth ?
                        <div>
                            <label>
                                <input onChange={this.loadPhoto.bind(this)} ref={this.some} type="file" className="none"/>
                                <img alt="" className={styles.Plus} src="/content/Icon/plus.png" />
                            </label>
                        </div>
                        : null
                }
                {
                    (this.state.licences.length + this.props.auth) % 2 !== 0 ?
                        <div></div>
                        : null
                }
            </div>
        );
    }

    bigPhoto = (src) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.AddNewItem}>
                        <img alt="" src={src} />
                    </div>
                );
            }
        });
    };

    componentDidMount() {
        this.loadAllPhoto();
    }

    loadAllPhoto = () => {
        fetch('api/licences/select').then(resp => resp.json()).then((data) => {
            this.setState({
                licences: data
            });
        });
    };

    loadPhoto = () => {
        const fd = new FormData();
        fd.append('picture', this.some.current.files[0]);
        fetch('api/licences/insert', {
            method: 'POST',
            body: fd
        })
            .then(resp => resp.json()).then((data) => {
                if (data.status) {
                    this.loadAllPhoto();
                } else {
                    alert("Some error");
                }
            });
    };

    deletePhoto = (src) => {
        confirmAlert({
            title: 'Confirm this action',
            message: 'Are you sure to delete this image?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        fetch('api/licences/delete', {
                            method: 'POST',
                            body: JSON.stringify({
                                src: src
                            })
                        }).then(resp => resp.json()).then((data) => {
                            if (data) {
                                this.loadAllPhoto();
                            } else {
                                alert("Some error");
                            }
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(licences);
