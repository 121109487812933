import initialState from "./state";

export default function reducer(state = initialState, action) {
    if (action.type === 'goTo') {
        return {
            ...state,
            nav: action.payload
        }
    }
    if (action.type === 'switchLang') {
        return {
            ...state,
            lang: action.payload
        }
    }
    if (action.type === 'switchToAdmin') {
        return {
            ...state,
            auth: action.payload
        }
    }
    if (action.type === 'showAdminLogin') {
        return {
            ...state,
            modal_window: true
        }
    }
    if (action.type === 'hideAdminLogin') {
        return {
            ...state,
            modal_window: false
        }
    }
    if (action.type === 'hideProjectsWindow') {
        return {
            ...state,
            projects: {
                ...state.projects,
                modal_window: false
            }
        }
    }
    if (action.type === 'showReadyProject') {
        return {
            ...state,
            projects: {
                title: "Оберіть категорію",
                modal_window: true,
                display_mode: 0,
                mode: 'ready',
                project: {
                    show: false,
                    id: 0
                }
            }
        }
    }
    if (action.type === 'showProgressProject') {
        return {
            ...state,
            projects: {
                title: "Оберіть категорію",
                modal_window: true,
                display_mode: 0,
                mode: 'progress',
                project: {
                    show: false,
                    id: 0
                }
            }
        }
    }
    if (action.type === 'goToProjectId') {
        return {
            ...state,
            projects: {
                ...state.projects,
                title: action.title,
                modal_window: true,
                display_mode: 2,
                project: {
                    show: true,
                    id: action.id
                },
                category: {
                    show: false,
                    id: 0
                }
            }
        }
    }
    if (action.type === 'goToCategoryId') {
        return {
            ...state,
            projects: {
                ...state.projects,
                title: action.title,
                modal_window: true,
                display_mode: 1,
                category: {
                    show: false,
                    id: action.id
                },
                project: {
                    show: false,
                    id: 0
                }
            }
        }
    }
    return state;
}
