
const url = window.location.href;

let arr = url.split('/');
const nav = arr[arr.length-1];

const initialState = {
    auth: false,
    modal_window: false,
    nav: 1,
    lang: "ua",
    projects: {
        title: 'default',
        modal_window: false,
        display_mode: 0,
        mode: 'ready',
        category: {
            show: false,
            id: 0
        },
        project: {
            show: false,
            id: 0
        }
    }
};

switch (nav) {
    case "main":
        initialState.nav = 1;
        break;
    case "services":
        initialState.nav = 2;
        break;
    case "projects":
        initialState.nav = 3;
        break;
    case "licences":
        initialState.nav = 4;
        break;
    default:
        initialState.nav = 1;
}

export default initialState;
