import React from 'react';
import styles from './ListOfProjects.module.css';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class ListOfProjects extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: []
        }
    }

    render() {
        return (
            <main className={styles.Main}>
                {
                    this.state.items.map((item, index) => {
                        return(
                            <div className={styles.Item} key={index}>
                                <h3 onClick={this.props.goToProjectId.bind(this, item.name, item.id)}>{item.name}</h3>
                                <img alt="" src={item.photos === null ? "/content/image_not_found.png" : item.photos}/>
                            </div>
                        );
                    })
                }
                {
                    this.props.auth ?
                    <div className={styles.Item + " " + styles.Plus} onClick={this.AddNew.bind(this)}>
                        <img alt="" src="/content/Icon/plus.png"/>
                    </div>
                        : null
                }
            </main>
        );
    }

    loadData = () => {
        const category = this.props.projects.category.id;
        fetch('api/category/get', {
            method: 'POST',
            body: JSON.stringify({
                'id': category
            })
        })
            .then(resp => resp.json())
            .then(projects => {
                projects.map((item, key) => {
                    fetch('api/project/preview', {
                        method: 'POST',
                        body: JSON.stringify({
                            id: item.id
                        })
                    }).then(resp => resp.json()).then(data => {
                        item.photos = data;
                    }).then(() => {
                        this.setState({
                            items: projects
                        });
                    });
                });
            });
    };

    async componentDidMount() {
        this.loadData();
    }

    AddNew = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                const nameRef = React.createRef();
                const descrRef = React.createRef();

                return (
                    <div className={styles.AddNewItem}>
                        <h1>Creating a new item</h1>
                        <input ref={nameRef} placeholder="Name of title"/>
                        <textarea ref={descrRef} placeholder="Write a description"></textarea>

                        <div className={styles.Panel}>
                            <button onClick={onClose}>No</button>
                            <button
                                onClick={() => {
                                    const data = {
                                        name: nameRef.current.value,
                                        description: descrRef.current.value,
                                        category: this.props.projects.category.id
                                    };
                                    fetch("api/project/create", {
                                        method: 'POST',
                                        body: JSON.stringify(data),
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    }).then(resp => resp.json()).then(data => {
                                        if (!data.status) {
                                            alert("Something error");
                                        } else {
                                            this.loadData();
                                        }
                                    });

                                    onClose();
                                }}
                            >
                                Create it!
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        projects: state.projects,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        goToProjectId: (set_title, set_id) => dispatch({type: 'goToProjectId', title: set_title, id: set_id})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOfProjects);
