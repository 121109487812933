import React from 'react';
import {connect} from 'react-redux';
import styles from './Footer.module.css';

class Footer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            header: {
                ru: "Контакты",
                ua: "Контакти"
            },
            description: {
                ru: "Свяжитесь с нами и вы получите бесплатную консультацию",
                ua: "Зв'яжіться з нами i вы отримаєте безкоштовну консультацiю"
            },
            instagram: {
                ru: "Наш инстаграм",
                ua: "Наш інстаграм"
            },
            copyright: {
                ru: "БК БУДСЕРВИСГРУПП. Все права защищены",
                ua: "БК БУДСЕРВІСГРУП. Всі права захищені"
            }
        }
    }

    render() {
        return (
            <section className={styles.Main}>
                <div className={styles.Shadow}>
                    <h3>{this.props.lang === "ru" ? this.state.header.ru : this.state.header.ua}</h3>
                    <p>{this.props.lang === "ru" ? this.state.description.ru : this.state.description.ua}</p>
                    <div className={styles.Contact}>
                        <div>
                            <img alt="" src="/content/Icon/phone.png"/>
                            <span><a href="tel:+380676565858">+38 (067) 656-58-58</a></span>
                        </div>
                        <div>
                            <img alt="" src="/content/Icon/message.png"/>
                            <span><a href="mailto:info@budservice.group">info@budservice.group</a></span>
                        </div>
                        <div>
                            <img alt="" src="/content/Icon/position.png"/>
                            <span>Київ вул.Татарська, будинок 3/7</span>
                        </div>
                        <div>
                            <img alt="" src="/content/Icon/instagram.png"/>
                            <span><a href="https://www.instagram.com/budservice.group/">{this.props.lang === "ru" ? this.state.instagram.ru : this.state.instagram.ua}</a></span>
                        </div>
                    </div>
                    <div>
                        <img alt=""/>
                        <span>© 2007-2019 {this.props.lang === "ru" ? this.state.copyright.ru : this.state.copyright.ua}</span>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        lang: state.lang
    }
}

export default connect(mapStateToProps)(Footer);
